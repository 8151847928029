import * as React from 'react';
import LoginForm from '../components/LoginForm';
import { useParams } from "react-router-dom"
export default function Login() {
  const { query } = useParams();
  return (
    <React.Fragment>
      <LoginForm query={query}></LoginForm>
    </React.Fragment>
  );
}