import React from "react";


export function TokenValid()
{  
  var token = localStorage.getItem("token");
  console.log(token);
  if (token === "" || token === null)
    return false;
  else
    return true;
}

export function TokenRedirect() {
    alert("Please login first.");
    window.history.replaceState("", "", "/login");
    window.location.replace("/login");
    return <React.Fragment/>;
}

