import { useState, useEffect } from 'react';
var parseString = require('xml2js').parseString;

export default function Report(type:string) {
  const [aryData, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [uTarget, setuTarget] = useState("intializing...");


  useEffect(() => {
    var target = "https://license.netlibsecurity.com/solo/authors/RptActivations.aspx?WebServiceLogin=True&AuthorID=2451871&UserID=4aaf144d-4176-40fc-8aa1-53a1ce6c2db6&UserPassword=77BD524A44574298113DE7D9B5654E0235F3571ABD657DA39A0A53FA671C07B71F9F8E8D9A4D4AFE1232CC9F614C48E68EFFCDD95EA9C72836046EE47B14E133&ReportType=xmlelements&StartDate=3/1/2023&EndDate=4/30/2023&SortBy=1";
    var url = "http://matchgames.sportsglance.com/relay.php?url=" + target.replaceAll("&", "%26");
    url = url.replace("xmlelements", type);
    setuTarget(url);
    fetch(url, {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/xml'
      })
    })
      .then((res) => res.text())
      .then((res2) => parseString(res2, function(err:any,  result:any){ 
        setData(result);
        setIsLoading(false);
      }))
  
      .catch((error) => console.log(error));
  },[type]);

  if (isLoading) {
    return (<span>{uTarget}</span>);
  }

  if (aryData == null){
    return(<span>empty</span>);
  }


  return (
    <div>
          <table>
          <tr>
            <th>Product ID</th>
            <th>ProductName</th>
            <th>ProdOptionID</th>
            <th>OptionName</th>
            <th>FirstTimeActivations</th>
            <th>TotalActivations</th>
            <th>TotalAdded</th>
            <th>TotalAddedActivated</th>
            </tr>
        {
          aryData.Activations.Record.map((data:any) => (
            <tr>
            <td>{data.ProductID}</td>
            <td>{data.ProductName}</td>
            <td>{data.ProdOptionID}</td>
            <td>{data.OptionName}</td>
            <td>{data.FirstTimeActivations}</td>
            <td>{data.TotalActivations}</td>
            <td>{data.TotalAdded}</td>
            <td>{data.TotalAddedActivated}</td>
            </tr>
        ))
      }
          </table>
    </div>
  )

}