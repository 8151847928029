
var attributes = [];

function setAttr(xmlNode) {
    var setExecs = false; // Assume no override
    var theExecs = "";    // Assume override is blank

    for (const key in attributes) {
        var node = xmlNode.querySelector(key);
        var sValue = "";

        if (key === "ExecsAllowed" || key === "IsExecsOverride" ) {
            if (node != null)
                sValue = node.innerHTML
            if (key === "ExecsAllowed") {
                theExecs = sValue;
            }
            if (key === "IsExecsOverride" && sValue.toUpperCase() === "TRUE") {
                setExecs = true;
            }
        }
        else {
            // Find the key in the custom parameters
            if (node !== null) {
                sValue = node.innerHTML;
                attributes[key] = sValue;
                console.log ("Value of " + key + " is: " + sValue)                
            }
        }   

        // If we are supposed to setExecs on this level, set it to whatever we got, or blank
        if (setExecs) {
            attributes["ExecsAllowed"] = theExecs;
            attributes["IsExecsOverride"] = "True";
        }
    }


}

function ParseXml(txt) {

    var parser = new DOMParser();
    attributes = [];
    attributes["LicenseID"] = "";
    attributes["OptionType"] = "";
    attributes["IsEval"] =  ""; // (T/F)
    attributes["IsTimeLtd"] =  ""; // (T/F)
    attributes["IsProd"] =  ""; // (T/F)
    attributes["IsServer"] =  ""; // (T/F)
    attributes["IsExecsOverride"] = "False"; //  (T/F) 
    attributes["ExecsAllowed"] = ""; // value or specify if blank
    attributes["SQLEdition"] =  ""; // int
    attributes["IsWholeDB"] = ""; //  (T/F)
    attributes["IsColumn"] = ""; //  (T/F)
    attributes["CoreCount"] = ""; //  int
    attributes["KeyLength"] = ""; //  int
    attributes["IsFolder"] = ""; //  (T/F)
    attributes["Instances"] = ""; //  int
    attributes["MaxSize"] = ""; //  int
    attributes["NumProcesses"] = ""; //  int
    attributes["NumFiles"] = ""; //  int
    attributes["IsLease"] = ""; //  (T/F)
    attributes["IsGUIsAllowed"] = ""; //  (T/F)
    attributes["IsCLIAllowed"] = "" // (T/F)
    attributes["IsLicenseUpdate"] = ""; //  (T/F)
    attributes["SysIdent"] = ""; //  (T/F)
    
    // Load the XML
    var xmlDocument= parser.parseFromString(txt.xml,"text/xml");
    var privateData = xmlDocument.querySelector("PrivateData");
    if (privateData !== null) {
        setAttr(privateData);
    }

    // Find Product, then find CustomData and CustomParameters. Base data is listed there.
    var product = xmlDocument.querySelector("Product");
    if (product !== null) {
        var customData = product.querySelector("CustomData");
        if (customData !== null) {
            var customParameters = customData.querySelector("CustomParameters");
            if (customParameters !== null) {
                setAttr(customParameters);
            }
        }
    }

    // Next use the ProductOption level
    var productOption = xmlDocument.querySelector("ProductOption");
    if (productOption !== null) {

        setAttr(productOption);

        customData = productOption.querySelector("CustomData");
        if (customData !== null) {
            customParameters = customData.querySelector("CustomParameters");
            if (customParameters !== null) {
                setAttr(customParameters);
            }
        }
    }

    // Next use the LicenseCustom Data level
    customData = xmlDocument.querySelector("LicenseCustomData");
    if (customData !== null) {
        customParameters = customData.querySelector("CustomParameters");
        if (customParameters !== null) {
            setAttr(customParameters);
        }
    }

    // Return the final set of attrs reformatted for viewing
    return formatAttributes(attributes);

}

// formatAttributes - take the attrs array and format for viewing
function formatAttributes(attributes) {
    let attrList = [];
    for (const key in attributes) {
        attrList.push(<li key={key}>{key}<span>: </span><b>{attributes[key]}</b></li>);
    }
    return <ul>{attrList}</ul>
}

export default function ParseLicense(xml) {
   return(ParseXml(xml));
}