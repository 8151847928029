import {useState} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import {GetDate} from "./GetDate";


export default function LoginForm(props) {
    
//var emailAddress = localStorage.getItem("email");
//var tokenValue = localStorage.getItem("token");

const [userName, setUserName] = useState("");
const [passWord, setPassWord] = useState("");

   /*
   ** setAuth - keep login token in state
   */
   function setAuth(doFunction, newEmail, newPassword) {
    if (doFunction === "login") {
        

        // Must be a valid login
        if (newEmail.indexOf("netlib") === -1) {
            alert("Must login with a netlib email address");
            return;
        }
        // Check if valid email
        if (newEmail === "reports@netlibsecurity.com" && newPassword === "R1netXml!") {
            localStorage.setItem("email", newEmail);
            localStorage.setItem("token", GetDate());
            //alert("Success:" + newEmail + ", " + newPassword);
            window.location.replace("/reportlist");
        }
    }
    else if (doFunction === "logout")
    {
      // Remove local storage
      localStorage.setItem("email", "");
      localStorage.setItem("token", "");

      // And go home
      window.location.replace('/');
    }
    else{
      alert("Unknown function:" + doFunction)
    }
   }

   if (props.query === "logout") {
      setAuth("logout", "", "");
      return(<span/>);
   }


return (

    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <Stack alignItems="center" spacing={2}>
        <img alt="netlib" width="100" src="/images/netlib200x200.png"/>
        <TextField
          required
          id="outlined-required"
          label="Username"
          onChange={(event) => setUserName(event.target.value)}
        />

        <TextField
          id="outlined-password-input"
          label="Password"
          type="password"
          autoComplete="current-password"
          onChange={(event) => setPassWord(event.target.value)}
        />

        <Button 
            variant="contained" 
            endIcon={<SendIcon />} 
            onClick={() => {
                setAuth("login", userName, passWord);}}>
            Login
          </Button>
      </Stack>
    </Box>
  );
}