import React from "react";
import { Routes, Route } from "react-router-dom";
import ReportList from "../pages/ReportList";
import SampleReport from "../pages/SampleReport";
import ReportPage from "../pages/ReportPage";
import Login from "../pages/Login";
import Home from "../pages/Home";
import TextboxSample from "../pages/TextboxSample";
import License from "../pages/License";

export class Router extends React.Component {

    render() { 
        return (
          <main>
            <Routes>
            <Route path="/" element={<Home/>} />            
            <Route path="/sample" element={<SampleReport/>} />
            <Route path="/reportlist" element={<ReportList/>} />  
            <Route path="/reports/:reportname" element={<ReportPage/>} />           
            <Route path="/login/:query" element={<Login/>} />
            <Route path="/login" element={<Login/>} />    
            <Route path="/textboxsample" element={<TextboxSample/>} />  
            <Route path="/license" element={<License/>} />   
        </Routes>
      </main>
    );
  }
}