import '../App.css';
import Message from '../components/Message';
import Report from '../components/Report';
function SampleReport() {

  var oOutput = Report("xmlelements");
  return (
    <div className="App">
      <header>
        <img width={200}  src="/images/netlib200x200.png" className="App-logo" alt="logo" />
      </header>
      <div>
      <Message important={false}>
            <span>Hello</span>
        </Message>
        {oOutput}
      </div>
    </div>
  );
}

export default SampleReport;
