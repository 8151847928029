import * as React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import {TokenValid, TokenRedirect} from "../components/Token";
import ActionCard from "../components/ActionCard";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  
export default function ReportList() {

    if (!TokenValid())
        return(TokenRedirect());

    return (
    <React.Fragment>
        
        <Grid container spacing={1}>
            <Grid item xs={12}>

            </Grid>
            <Grid item xs={2}>

            </Grid>

            <Grid item xs={2}>
                <ActionCard title="Activations" description="List of activations by customer, listing product name, id, activation date, and optionally deactivations" link="/reports/RptActivationDataExport"/>
             </Grid>
             <Grid item xs={2}>
                <ActionCard title="Downloads" description="Software downloads by customer, with version number, download date, product name, option name, and IP address" link="/reports/RptESDDownloadLog"/>
             </Grid>
             <Grid item xs={2}>
                <ActionCard title="Licenses" description="List of licenses by customer, with number of activations, deactivations, licensee name, and license group" link="/reports/RptExportLicenses"/>
             </Grid>
             <Grid item xs={2}>
                <ActionCard title="Summary" description="List of product and option combinations, showing the number of first time activations, total activations, and activations added" link="/reports/RptActivations"/>
             </Grid>


        </Grid>


    </React.Fragment>
    )
}