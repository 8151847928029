import { generateCsv, download } from 'export-to-csv';
//import { csvConfig } from './CsvConfig'
import CsvConfig from './CsvConfig';

export default class RptActivations {

    constructor() {
        if (this.constructor.instance) {
            return this.constructor.instance;
          }
          this.constructor.instance = this;
    }
    
  // Get Visibility based on details (TBD!!!)
  getVisibility(extraCols) {
    if (extraCols) {
      return {IPAddress: true, ActivationType:true, ResultCode:true, isTest:false, isActive:false, isSuccess:false } 
    }
    else {
      return { IPAddress: false, ActivationType:false, ResultCode:false, isTest:false, isActive:false, isSuccess:false }
    }
  }

  // dataTableColumns - the definition of the columns
  dataTableColumns() {
    return(

    [
        {
          accessorKey: "ProductID", 
          header: "ProductID",
        },
        {
          accessorKey: "ProductName", 
          header: "ProductName",
        },
        {
          accessorKey: "ProdOptionID", 
          header: "ProdOptionID",
        },
        {
          accessorKey: "OptionName", 
          header: "OptionName",
        },
        {
          accessorKey: "FirstTimeActivations", 
          header: "FirstTimeActivations",
        },
        {
          accessorKey: "TotalActivations", 
          header: "TotalActivations",
        },
        {
          accessorKey: "TotalAdded", 
          header: "TotalAdded",
        },
        {
          accessorKey: "TotalAddedActivated", 
          header: "TotalAddedActivated",
        }                                    
      ]
    )
}

// handleExportRows - sorted and selected data
handleExportRows = (rows, extraCols) => {

    const CSVConfig = new CsvConfig();
    const csvConfig = CSVConfig.makeCsvConfig();

      var rowData;
      rowData = rows.map(
        function(row) {
          return { 
            "ProductID" : row.original.ProductID, 
            "ProductName" : row.original.ProductName, 
            "ProdOptionID" : row.original.ProdOptionID, 
            "OptionName" : row.original.OptionName, 
            "FirstTimeActivations" : row.original.FirstTimeActivations, 
            "TotalActivations" : row.original.TotalActivations, 
            "TotalAdded" : row.original.TotalAdded, 
            "TotalAddedActivated" : row.original.TotalAddedActivated,             
          }
        }
      )

    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  // handleExportData - all data
  handleExportData = (rows) => {
    const CSVConfig = new CsvConfig();
    const csvConfig = CSVConfig.makeCsvConfig();    
    const csv = generateCsv(csvConfig)(rows);
    download(csvConfig)(csv);
  };

}