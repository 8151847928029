import { mkConfig } from 'export-to-csv';

export default class CsvConfig {

constructor() {
    if (this.constructor.instance) {
        return this.constructor.instance;
      }
      this.constructor.instance = this;
}

makeCsvConfig() {

    const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
    quoteStrings: true,
  });

  return(csvConfig);

}

}