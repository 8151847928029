function twoDigit(n) { return (n < 10 ? '0' : '') + n; }

export function GetDate() {

    var now = new Date();
    return '' + now.getFullYear() + twoDigit(now.getMonth() + 1) + twoDigit(now.getDate());
}
export function GetUSDate() {
    var now = new Date();
    return '' + twoDigit(now.getMonth() + 1) + '/' + twoDigit(now.getDate()) + '/' + now.getFullYear();
}
export function GetUSDateString(dateVal) {
    var now = new Date(dateVal);
    return '' + twoDigit(now.getMonth() + 1) + '/' + twoDigit(now.getDate()) + '/' + now.getFullYear();
}