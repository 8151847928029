import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ParseLicense from '../components/ParseLicense';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import {TokenValid, TokenRedirect} from "../components/Token";

export default function License() {
    const [text, setText] = useState('');

    function handleChange(e) {
      setText(e.target.value);
    }

  if (!TokenValid())
      return(TokenRedirect());

  return (
    <Grid container spacing={2}>
      <Grid item xs={1}></Grid>
      <Grid item xs={5}>
        <TextField
          required
          id="filled-required"
          label="XML Data"
          variant="filled"
          value={text}
          rows="22"
          fullWidth
          onChange={handleChange}
          multiline
        />
    </Grid>
    <Grid item xs={6}>
      <ParseLicense xml={text}/>
    </Grid>
    <Grid item xs={1}></Grid>
    <Grid item xs={6}>
      <Button variant="contained" onClick={() => {setText("");}}>
        Clear
      </Button>
    </Grid>
  </Grid>
  )
}