interface MessageProps {
  children: JSX.Element | JSX.Element[];
  important?: boolean;
}

function Message({ children, important = false }: MessageProps) {
  return (
    <div>
      {IsImportant(important)}
      {children}
  
    </div>
  );
}

function IsImportant(bImportant: boolean): string {
  if (bImportant) {
    return("Important Message: ")
  }
  else{
    return("Regular Message: ")
  }
}

export default Message;