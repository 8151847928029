import { generateCsv, download } from 'export-to-csv';
import { Box } from '@mui/material';
import CsvConfig from './CsvConfig';

export default class RptActivationDataExport {

  constructor() {
    if (this.constructor.instance) {
        return this.constructor.instance;
      }
      this.constructor.instance = this;
}

  getVisibility(extraCols) {
    if (extraCols) {
      return  {IPAddress: true,  ActivationType:true,  ResultCode:true,  DeactivatedBy:true,  ExtraInfo:true,  isTest:false, isActive:false, isSuccess:false } 
    }
    else {
      return { IPAddress: false, ActivationType:false, ResultCode:false, DeactivatedBy:false, ExtraInfo:false, isTest:false, isActive:false, isSuccess:false }
    }
  }

  // dataTableColumns - the definition of the columns
  dataTableColumns() {
  return(
  [
    {
      accessorKey: "CompanyName",
      header: "Company",
      filterFn: 'contains',
    },   
    {
      accessorKey: "CustomerID", 
      header: "CustomerID",
      filterFn: 'contains',
      Cell: ({ cell }) => (<Box sx={{color:'blue'}}>{cell.getValue()}</Box>),      
      muiTableBodyCellProps: ({ cell, column, table }) => ({
        onDoubleClick: (event) => {
          console.info(event.target.innerText);
          window.open("https://license.netlibsecurity.com/solo/authors/CustomerDetails.aspx?CustomerID="+event.target.innerText, '_blank')
        },
      }),      
    },     
    {
      accessorKey: "LicenseID", 
      header: "LicenseID",
      filterFn: 'contains',
      Cell: ({ cell }) => (<Box sx={{color:'blue'}}>{cell.getValue()}</Box>),          
      muiTableBodyCellProps: ({ cell, column, table }) => ({
        onDoubleClick: (event) => {
          console.info(event.target.innerText);
          window.open("https://license.netlibsecurity.com/solo/authors/orderdetail.asp?Action=View&LicenseID="+event.target.innerText, '_blank')
        },
      }),        
    },
    {
      accessorKey: "ProductName", 
      header: "ProductName",
      filterFn: 'contains',
    },
    {
      accessorKey: "OptionName", 
      header: "OptionName",
      filterFn: 'contains',
    },
    {
      accessorKey: "ActivationDate", 
      header: "ActivationDate",
      filterVariant: 'date-range',
      accessorFn: (originalRow) => new Date(originalRow.ActivationDate),
      Cell: ({ cell }) => (cell.getValue() === '' ? '' : cell.getValue().toLocaleString('sv-SE')),
    },
    {
      accessorKey: "InstallationName", 
      header: "InstallationName",
      filterFn: 'contains',
    },
    {
      accessorKey: "InstallationID", 
      header: "InstallationID",
      filterFn: 'contains',
      Cell: ({ cell }) => (<Box sx={{color:'blue'}}>{cell.getValue()}</Box>),
      muiTableBodyCellProps: ({ cell, column, table }) => ({
        onDoubleClick: (event) => {
          console.info(event.target.innerText);
          window.open("https://license.netlibsecurity.com/solo/authors/ActivationDetail.aspx?InstallationID="+event.target.innerText, '_blank')
        },
      }),
    },
    {
      accessorKey: "ActivationType", 
      header: "ActivationType",
      filterFn: 'contains',
    },
    {
      accessorKey: "IPAddress", 
      header: "IPAddress",
      filterFn: 'contains',
    },
    {
      accessorKey: "ResultCode", 
      header: "ResultCode",
      filterFn: 'contains',
    },
    {
      accessorKey: "DeactivatedDate",
      header: "DeactivatedDate",
      filterVariant: 'date-range',
      accessorFn: (originalRow) => (originalRow === undefined || originalRow.DeactivatedDate === undefined || originalRow.DeactivatedDate[0] === "" ? "" : new Date(originalRow.DeactivatedDate)),
      Cell: ({ cell }) => (cell.getValue() === '' ? '' : cell.getValue().toLocaleString('sv-SE')),
    },    
    {
      accessorKey: "DeactivatedBy", 
      header: "DeactivatedBy",
      filterFn: 'contains',
    },
    {
      accessorKey: "ExtraInfo", 
      header: "ExtraInfo",
      filterFn: 'contains',
    },    
    {
      header: 'Success',
      accessorFn: (originalRow) => (originalRow === undefined || originalRow.ResultCode === undefined ? 'false' : (originalRow.ResultCode[0] === "0" ? 'true' : 'false')), //must be strings
      id: 'isSuccess',
      filterVariant: 'checkbox',
      Cell: ({ cell }) =>
        cell.getValue() === 'true' ? 'Success' : 'Failure',
      size: 170,
    },    
    {
      header: 'Status',
      accessorFn: (originalRow) => (originalRow === undefined || originalRow.DeactivatedDate === undefined ? 'false' : (originalRow.DeactivatedDate[0] === "" ? 'true' : 'false')), //must be strings
      id: 'isActive',
      filterVariant: 'checkbox',
      Cell: ({ cell }) =>
        cell.getValue() === 'true' ? 'Active' : 'Inactive',
      size: 170,
    },
    {
      header: 'Test',
      accessorFn: (originalRow) => (originalRow === undefined || originalRow.CompanyName === undefined ? 'false' : (originalRow.CompanyName[0].includes("TEST") ? 'true' : 'false')), //must be strings
      id: 'isTest',
      filterVariant: 'checkbox',
      Cell: ({ cell }) =>
        cell.getValue() === 'true' ? 'Test' : 'Prod',
      size: 170,
    }
                     
  ]
  )

  }

/*
Source (hard coded with “License Portal”
Company
CustomerID
LicenseID
ProductDescr (concatenate ProductName,space,OptionName)
Activation Date (present as date/time - remove T as in the display)
InstallationName
InstallationID
DeactivatedDate (only if Hide Deactivate not selected)
If Add Details selected, add columns
ActivationType
IPAddress
ResultCode
DeactivatedDate
*/

 // handleExportRows - sorted and selected data
 handleExportRows = (rows, extraCols) => {

  if (rows === undefined)
    return;

    const CSVConfig = new CsvConfig();
    const csvConfig = CSVConfig.makeCsvConfig();
    if (!extraCols) {
      var rowData;
      rowData = rows.map(
        function(row) {
          return { 
            "Source": "License Portal",
            "CompanyName" : row.original.CompanyName[0], 
            "CustomerID" : row.original.CustomerID, 
            "LicenseID" : row.original.LicenseID, 
            "ProductDescr" : row.original.ProductName + " " + row.original.OptionName, 
            "ActivationDate" : row.original.ActivationDate, 
            "InstallationName" : row.original.InstallationName, 
            "InstallationID" : row.original.InstallationID, 
            "DeactivatedDate" : row.original.DeactivatedDate,                      
          }
        }
      )
    }
    else {
      rowData = rows.map(
        function(row) {
          return { 
            "Source": "License Portal",
            "CompanyName" : row.original.CompanyName[0], 
            "CustomerID" : row.original.CustomerID, 
            "LicenseID" : row.original.LicenseID, 
            "ProductDescr" : row.original.ProductName + " " + row.original.OptionName, 
            "ActivationDate" : row.original.ActivationDate, 
            "InstallationName" : row.original.InstallationName, 
            "InstallationID" : row.original.InstallationID,             
            "ActivationType": row.original.ActivationType, 
            "IPAddress": row.original.IPAddress, 
            "ResultCode": row.original.ResultCode,
            "DeactivatedDate" : row.original.DeactivatedDate,             
            "DeactivatedBy" : row.original.DeactivatedBy,    
            "ExtraInfo" : row.original.ExtraInfo,          
          }
        }
      )
    }
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  // handleExportData - all data
  handleExportData = (rows, extraCols) => {
    if (rows === undefined)
      return;
  
    const CSVConfig = new CsvConfig();
    const csvConfig = CSVConfig.makeCsvConfig();
    if (!extraCols) {
        var rowData;
        rowData = rows.map(
          function(row) {
            return { 
              "Source": "License Portal",
              "CompanyName" : row.CompanyName[0], 
              "CustomerID" : row.CustomerID, 
              "LicenseID" : row.LicenseID, 
              "ProductDescr" : row.ProductName + " " + row.OptionName, 
              "ActivationDate" : row.ActivationDate, 
              "InstallationName" : row.InstallationName, 
              "InstallationID" : row.InstallationID, 
              "DeactivatedDate" : row.DeactivatedDate,             
            }
          }
        )
      }
      else {
        rowData = rows.map(
          function(row) {
            return { 
              "Source": "License Portal",
              "CompanyName" : row.CompanyName[0], 
              "CustomerID" : row.CustomerID, 
              "LicenseID" : row.LicenseID, 
              "ProductDescr" : row.ProductName + " " + row.OptionName, 
              "ActivationDate" : row.ActivationDate, 
              "InstallationName" : row.InstallationName, 
              "InstallationID" : row.InstallationID,             
              "ActivationType": row.ActivationType, 
              "IPAddress": row.IPAddress, 
              "ResultCode": row.ResultCode,
              "DeactivatedDate" : row.DeactivatedDate,     
              "DeactivatedBy" : row.DeactivatedBy,  
              "ExtraInfo" : row.ExtraInfo,       
            }
          }
        )
      }
      const csv = generateCsv(csvConfig)(rowData);
      download(csvConfig)(csv);
  };


}