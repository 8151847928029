import { generateCsv, download } from 'export-to-csv';
import { Box } from '@mui/material';
import CsvConfig from './CsvConfig';

export default class RptExportLicenses {

  constructor() {
    if (this.constructor.instance) {
        return this.constructor.instance;
      }
      this.constructor.instance = this;
}

  // Get Visibility based on details (TBD!!!)
  getVisibility(extraCols) {
    if (extraCols) {
      return {ActivationPassword: true, ProductID:true, ProdOptionID:true, LicenseEnteredBy:true, LicenseLicenseeName:true, LicenseGroup:true, ResultCode:false, isTest:false, isActive:false, isSuccess:false } 
    }
    else {
      return { ActivationPassword: false, ProductID:false, ProdOptionID:false, LicenseEnteredBy:false, LicenseLicenseeName:false, LicenseGroup:false, ResultCode:false, isTest:false, isActive:false, isSuccess:false }
    }
  }

  // dataTableColumns - the definition of the columns
  dataTableColumns() {
  return(
  [
    {
      accessorKey: "LicenseEnteredDate", 
      header: "LicenseEnteredDate",
      filterVariant: 'date-range',
      accessorFn: (originalRow) => new Date(originalRow.LicenseEnteredDate),
      Cell: ({ cell }) => (cell.getValue() === '' ? '' : cell.getValue().toLocaleString('sv-SE')),
    },
    {
      accessorKey: "CompanyName",
      header: "CompanyName",
      filterFn: 'contains',
    },
    {
      accessorKey: "CustomerID", 
      header: "CustomerID",
      filterFn: 'contains',
      Cell: ({ cell }) => (<Box sx={{color:'blue'}}>{cell.getValue()}</Box>),      
      muiTableBodyCellProps: ({ cell, column, table }) => ({
        onDoubleClick: (event) => {
          console.info(event.target.innerText);
          window.open("https://license.netlibsecurity.com/solo/authors/CustomerDetails.aspx?CustomerID="+event.target.innerText, '_blank')
        },
      }),          
    },
    {
      accessorKey: "LicenseID", 
      header: "LicenseID",
      filterFn: 'contains',
      Cell: ({ cell }) => (<Box sx={{color:'blue'}}>{cell.getValue()}</Box>),          
      muiTableBodyCellProps: ({ cell, column, table }) => ({
        onDoubleClick: (event) => {
          console.info(event.target.innerText);
          window.open("https://license.netlibsecurity.com/solo/authors/orderdetail.asp?Action=View&LicenseID="+event.target.innerText, '_blank')
        },
      }),        
    },
    {
      accessorKey: "ProductName", 
      header: "ProductName",
      filterFn: 'contains',
    },
    {
      accessorKey: "OptionName", 
      header: "OptionName",
      filterFn: 'contains',
    },
    {
      accessorKey: "ActivationPassword", 
      header: "ActivationPassword",
      filterFn: 'contains',
    },
    {
      accessorKey: "Status", 
      header: "Status",
      filterFn: 'contains',
    },
    {
      accessorKey: "ProductID", 
      header: "ProductID",
      filterFn: 'contains',
    },
    {
        accessorKey: "ProdOptionID", 
        header: "ProdOptionID",
        filterFn: 'equals',
      },
    {
      accessorKey: "UnlocksLeft", 
      header: "Activations Left",
      filterFn: 'contains',
    },
    {
      accessorKey: "LicenseCounter", 
      header: "LicenseCounter",
      filterFn: 'contains',
    },
    {
      accessorKey: "ExpirationDate",
      header: "ExpirationDate",
      filterVariant: 'date-range',
      accessorFn: (originalRow) => new Date(originalRow.ExpirationDate),
      Cell: ({ cell }) => (cell.getValue() === '' ? '' : cell.getValue().toLocaleString('sv-SE')),
     },
     {
      accessorKey: "LicenseEnteredBy", 
      header: "LicenseEnteredBy",
      filterFn: 'contains',
    },
    {
      accessorKey: "LicenseLicenseeName", 
      header: "LicenseLicenseeName",
      filterFn: 'contains',
    },
    {
      accessorKey: "LicenseGroup", 
      header: "LicenseGroup",
      filterFn: 'contains',
    },             
    {
      header: 'Test',
      accessorFn: (originalRow) => (originalRow === undefined || originalRow.CompanyName === undefined ? 'false' : (originalRow.CompanyName[0].includes("TEST") ? 'true' : 'false')), //must be strings
      id: 'isTest',
      filterVariant: 'checkbox',
      Cell: ({ cell }) =>
        cell.getValue() === 'true' ? 'Test' : 'Prod',
      size: 170,
    } ,
    {
      header: 'Active', // Fake Column
      accessorFn: (originalRow) => "true",
      id: 'isActive',
      filterVariant: 'checkbox',
      Cell: ({ cell }) =>
        cell.getValue() === 'true' ? 'Active' : 'Active',
      size: 170,
    },
    {
      header: 'ResultCode', // Fake Column
      accessorFn: (originalRow) => "0",
      id: 'ResultCode',
      Cell: ({ cell }) =>
        cell.getValue() === 'true' ? '0' : '0',
      size: 170,
    },
    {
      header: 'Success', // Fake Column
      accessorFn: (originalRow) => "true",
      id: 'isSuccess',
      filterVariant: 'checkbox',
      Cell: ({ cell }) =>
        cell.getValue() === 'true' ? 'Success' : 'Success',
      size: 170,
    },           
  ]

)

  }

 // handleExportRows - sorted and selected data
 handleExportRows = (rows, extraCols) => {

  if (rows === undefined)
    return;

    const CSVConfig = new CsvConfig();
    const csvConfig = CSVConfig.makeCsvConfig();
    if (!extraCols) {
      var rowData;
      rowData = rows.map(
        function(row) {
          return { 
            "LicenseEnteredDate" : row.original.LicenseEnteredDate, 
            "CompanyName" : row.original.CompanyName[0], 
            "CustomerID" : row.original.CustomerID, 
            "LicenseID" : row.original.LicenseID, 
            "ProductName" : row.original.ProductName, 
            "OptionName" : row.original.OptionName,          
            "Status": row.original.Status, 
            "UnlocksLeft" : row.original.UnlocksLeft,             
            "LicenseCounter" : row.original.LicenseCounter,             
            "ExpirationDate" : row.original.ExpirationDate,                        
          }
        }
      )
    }
    else {
      rowData = rows.map(
        function(row) {
          return { 
            "LicenseEnteredDate" : row.original.LicenseEnteredDate, 
            "CompanyName" : row.original.CompanyName[0], 
            "CustomerID" : row.original.CustomerID, 
            "LicenseID" : row.original.LicenseID, 
            "ProductName" : row.original.ProductName, 
            "OptionName" : row.original.OptionName, 
            "ActivationPassword" : row.original.ActivationPassword,             
            "Status": row.original.Status, 
            "ProductID": row.original.ProductID, 
            "ProdOptionID": row.original.ProdOptionID,
            "UnlocksLeft" : row.original.UnlocksLeft,             
            "LicenseCounter" : row.original.LicenseCounter,             
            "ExpirationDate" : row.original.ExpirationDate,             
            "LicenseEnteredBy" : row.original.LicenseEnteredBy,             
            "LicenseLicenseeName" : row.original.LicenseLicenseeName,             
            "LicenseGroup" : row.original.LicenseGroup,             
          }
        }
      )
    }
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  // handleExportData - all data
  handleExportData = (rows, extraCols) => {
    if (rows === undefined)
      return;

      if (rows === undefined)
      return;
  
      const CSVConfig = new CsvConfig();
      const csvConfig = CSVConfig.makeCsvConfig();
      if (!extraCols) {
        var rowData;
        rowData = rows.map(
          function(row) {
            return { 
              "LicenseEnteredDate" : row.LicenseEnteredDate, 
              "CompanyName" : row.CompanyName[0], 
              "CustomerID" : row.CustomerID, 
              "LicenseID" : row.LicenseID, 
              "ProductName" : row.ProductName, 
              "OptionName" : row.OptionName,           
              "Status": row.Status, 
              "UnlocksLeft" : row.UnlocksLeft,             
              "LicenseCounter" : row.LicenseCounter,             
              "ExpirationDate" : row.ExpirationDate,               
            }
          }
        )
      }
      else {
        rowData = rows.map(
          function(row) {
            return { 
              "LicenseEnteredDate" : row.LicenseEnteredDate, 
              "CompanyName" : row.CompanyName[0], 
              "CustomerID" : row.CustomerID, 
              "LicenseID" : row.LicenseID, 
              "ProductName" : row.ProductName, 
              "OptionName" : row.OptionName, 
              "ActivationPassword" : row.ActivationPassword,             
              "Status": row.Status, 
              "ProductID": row.ProductID, 
              "ProdOptionID": row.ProdOptionID,
              "UnlocksLeft" : row.UnlocksLeft,             
              "LicenseCounter" : row.LicenseCounter,             
              "ExpirationDate" : row.ExpirationDate,             
              "LicenseEnteredBy" : row.LicenseEnteredBy,             
              "LicenseLicenseeName" : row.LicenseLicenseeName,             
              "LicenseGroup" : row.LicenseGroup,               
            }
          }
        )
      }
      const csv = generateCsv(csvConfig)(rowData);
      download(csvConfig)(csv);
  };


}