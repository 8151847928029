import * as React from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import CssBaseline from '@mui/material/CssBaseline';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import './index.css';
import { Router } from "./components/Router";
import ResponsiveAppBar from "./components/ResponsiveAppBar";
function App() {

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <React.Fragment>
      <CssBaseline />
      <header>
        <ResponsiveAppBar/>
      </header>
      <div>
      <Router />
      </div>
    </React.Fragment>
    </LocalizationProvider>
  );
}

export default App;
