
/*
** filterData - remove Test customer or Deactivations from results array
*/
export function filterData(arr, excludeTest, excludeDeactive, excludeCustomers, includeCustomers) {

  // Set index into data (or just return)
  var Record = [];
  if (typeof arr.ActivationDataExport !== "undefined") 
    Record = arr.ActivationDataExport.Record;
  else 
    if (typeof arr.ExportLicenses !== "undefined")
      Record = arr.ExportLicenses.Record
    else
      return;
    

  var aryExclude = excludeCustomers.split(",");
  var aryInclude = includeCustomers.split(",");

  // for( var i = 0; i < arr.ActivationDataExport.Record.length; i++){ 
  for( var i = 0; i < Record.length; i++){ 
      var removed = false;

    /*
    if (excludeTest) {
      if ( arr.ActivationDataExport.Record[i].CompanyName[0].includes("TEST")) { 
        arr.ActivationDataExport.Record.splice(i, 1); 
        i--; 
        removed = true;
      }
    }

    if (excludeDeactive && !removed) {
      if ( arr.ActivationDataExport.Record[i].DeactivatedDate[0].includes("T")) { 
        arr.ActivationDataExport.Record.splice(i, 1); 
        i--; 
        removed = true;
      }
    }
    */

    
    // For each excluded string, see if it is in the company name. Once we've deleted this record we can just move on
    let a = 0;
    while (a < aryExclude.length && excludeCustomers !== "" && !removed) {
      if ( Record[i].CompanyName[0].toUpperCase().trim().includes(aryExclude[a].toUpperCase().trim())) { 
        // If so, delete this element
        Record.splice(i, 1); 
        i--; 
        removed = true;
      }
      a++;
    }

    // This is harder. If the company name matches at least one include string, then we keep the record. Otherwise we delete it.
    // And of course only execute if we haven't already removed the record and we have an include string array
    if (!removed && aryInclude.length > 0 && includeCustomers !== "") {
      var willInclude = false;
      a = 0;
      while (a < aryInclude.length) {
        if (Record[i].CompanyName[0].toUpperCase().trim().includes(aryInclude[a].toUpperCase().trim())) { 
          willInclude = true;
        }
        a++;
      }

      if (!willInclude) {
        Record.splice(i, 1); 
        i--; 
        removed = true;
      }

    }



  }
  return(arr);
}
